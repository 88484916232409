import React from 'react'
import './Main.css'
import { BrowserRouter, Link, Route, Switch } from 'react-router-dom'

import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/database'

import { useAuthState } from 'react-firebase-hooks/auth'
import { postJson } from './util'
import { Users } from './Users'
import { auth } from './persistence'

const meta = new Promise(async resolve => {
  const res = await fetch('/meta')
  const meta = await res.json()
  console.log('-----------> meta:', meta)
  resolve(meta)
})

export function MainWithAuth() {
  const [user] = useAuthState(auth)

  return (
    <div className="App">
      {user ? <MainAfterLogin/> : <SignIn/>}
    </div>
  )
}

function SignIn() {
  const signInWithGoogle = () => {
    const provider = new firebase.auth.GoogleAuthProvider()
    auth.signInWithPopup(provider)
  }

  return (
    <>
      <button className="sign-in" onClick={signInWithGoogle}>Sign in with Google</button>
    </>
  )
}

let tokenSent

function SignOut() {
  return auth.currentUser && (
    <button className="sign-out" onClick={() => auth.signOut()}>Sign Out</button>
  )
}

let supportedTypes = ({ TYPE_TEST }) => typeof TYPE_TEST === 'string' && ['day 2 lft',
  'fit to fly lft'].includes(TYPE_TEST.trim().toLowerCase())

export class MainAfterLogin extends React.Component {
  constructor() {
    super()
    this.state = {
      text: '',
      entries: [],
      status: 'loading...',
      to: '',
      sendButtonLabel: 'Send 0 Emails',
      profile: null,
      admin: false
    }
  }

  componentDidMount() {
    console.log('----------------> componentDidMount')
    this.amIAdmin().then(admin => {
      console.log('--------------->', { admin })
      this.setState({ admin })
    })

    firebase.auth().onIdTokenChanged(user => {
      if (user) {
        console.log('----------------> onIdTokenChanged:', user.providerData)

        user.getIdToken(true).then(async idToken => {
          if (tokenSent === idToken) {
            return
          }
          tokenSent = idToken

          let res = await postJson('/token', { idToken })
          if (res.status === 401) {
            this.setState({ status: 'Unauthorised Access' })
            await auth.signOut()
            return
          } else if (res.status !== 200) {
            this.setState({ status: 'Error while talking to server:' + res.status })
            return
          }
          const profile = await res.json()
          res = await fetch('/api/entries')
          const entries = await res.json()
          this.setState({ profile, entries, sendButtonLabel: `Send ${entries.length} Emails` })
        }).catch(function (error) {
          console.error('------------>', error)
        })
      } else {
        console.log('----------------> 2')
      }
    })
  }

  async setText(event) {
    const text = event.target.value
    const entries = text.split('\n').map(line => {
      let ADDRESS, ADDRESS_SELF_ISOLATING, BARCODE, BOOKINGREFERENCENUMBER, CERT, CITY,
        CONFIRMED, COUNTRIES_FROM, COUNTRY_LEVEL, DATE_ARRIVED_UK,
        DATE_DEPARTED_UK, DATE_TEST, DEPARTED_COUNTRY_STATUS, DOB, EMAIL, EMAIL_FORM,
        ETHNICITY, FLIGHT, FORENAMES, HOME_ADDRESS, ID_NUMBER, ID_TYPE,
        ISOLATION_ADDRESS, ISOLATION_POSTCODE, LOCATION, NHS_NUM, PHONE, PHOTO_URL, POSTCODE,
        POSTCODE_SELF_ISOLATING, RESULTS, SAMPLE_DATE, SEX, SURNAME, TIMESTAMP, TIME_TEST,
        TYPE_TEST, TYPE_TRANSPORT, VACCINATION_STATUS, SECOND_LINE_OF_ADDRESS, TRACKING_NUMBER, REPORTED

      const pyser = window.location.search.substr(1).split('&').includes('pyser') || window.location.host.startsWith('pyser')

      let fields = line.split('\t')
      if (pyser) {
        ([LOCATION, CERT, SAMPLE_DATE, RESULTS, BARCODE, BOOKINGREFERENCENUMBER, FORENAMES, SURNAME, DOB, SEX,
          TYPE_TEST, EMAIL, PHONE, ADDRESS, POSTCODE, ETHNICITY, VACCINATION_STATUS, ID_NUMBER, ID_TYPE, NHS_NUM,
          ADDRESS_SELF_ISOLATING, POSTCODE_SELF_ISOLATING, DATE_DEPARTED_UK, DATE_ARRIVED_UK, COUNTRIES_FROM, FLIGHT,
          COUNTRY_LEVEL, TYPE_TRANSPORT] = fields)
      } else {
        ([

          TIMESTAMP, DATE_TEST, TIME_TEST, EMAIL_FORM, EMAIL, FORENAMES, SURNAME, DOB, SEX, TYPE_TEST, RESULTS, BARCODE,
          BOOKINGREFERENCENUMBER, ID_TYPE, ID_NUMBER, PHONE, ETHNICITY, HOME_ADDRESS, SECOND_LINE_OF_ADDRESS, POSTCODE,
          CITY, DATE_DEPARTED_UK, DATE_ARRIVED_UK, TYPE_TRANSPORT, FLIGHT, DEPARTED_COUNTRY_STATUS, COUNTRIES_FROM, ,
          ISOLATION_ADDRESS, ISOLATION_POSTCODE, TRACKING_NUMBER, VACCINATION_STATUS, NHS_NUM, PHOTO_URL, CONFIRMED,
          REPORTED,

        ] = fields)
        SAMPLE_DATE = `${DATE_TEST} ${TIME_TEST}`
      }
      return {
        ADDRESS, ADDRESS_SELF_ISOLATING, BARCODE, BOOKINGREFERENCENUMBER, CERT, CITY,
        CONFIRMED, COUNTRIES_FROM, COUNTRY_LEVEL, DATE_ARRIVED_UK,
        DATE_DEPARTED_UK, DATE_TEST, DEPARTED_COUNTRY_STATUS, DOB, EMAIL, EMAIL_ADDRESS: EMAIL_FORM,
        ETHNICITY, FLIGHT, FORENAMES, HOME_ADDRESS, ID_NUMBER, ID_TYPE,
        ISOLATION_ADDRESS, ISOLATION_POSTCODE, LOCATION, NHS_NUM, PHONE, PHOTO_URL, POSTCODE,
        POSTCODE_SELF_ISOLATING, RESULTS, SAMPLE_DATE, SEX, SURNAME, TIMESTAMP, TIME_TEST,
        TYPE_TEST, TYPE_TRANSPORT, VACCINATION_STATUS
      }
    }).filter(supportedTypes)
    this.setState({ text, entries, sendButtonLabel: `Send ${entries.length} Emails` })
    console.log('------------->', entries)

    try {
      const res = await fetch('/api/entries', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(entries)
      })
      if (res.status === 401) {
        this.setState({ status: 'Unauthorised Access' })
        await auth.signOut()
      }
    } catch (e) {
      console.error(e)
    }
  }

  async sendEmail(index) {
    let { entries } = this.state
    const entry = entries[index]
    entry.status = 'sending...'
    this.setState({ entries })
    const res = await fetch(`/api/email?index=${index}&to=${this.state.to}`)
    if (res.status === 401) {
      this.setState({ status: 'Unauthorised Access' })
      await auth.signOut()
      return
    } else if (res.status === 200) {
      entry.status = 'sent'
    } else {
      entry.status = await res.text()
    }
    this.setState({ entries })
  }

  async sendEmails() {
    for (let i = 0; i < this.state.entries.length; i++) {
      await this.sendEmail(i)
    }
  }

  main() {
    return (
      <div>
        <textarea style={{ width: '100%', height: '200px' }}
                  onChange={this.setText.bind(this)} value={this.state.text}/>
        <div>
          <div>Send to this email instead:<input value={this.state.to}
                                                 onChange={e => this.setState({ to: e.target.value })}/></div>
        </div>
        <button onClick={() => this.sendEmails()}>{this.state.sendButtonLabel}</button>
        <table className="entriesTable">
          {this.state.entries.map((entry, index) =>
            <tr key={index} className="entryRow">
              <td className="name">{entry.FORENAMES} {entry.SURNAME}</td>
              <td className="email">{entry.EMAIL}</td>
              <td className="results">{entry.RESULTS}</td>
              <td className="type_test">{entry.TYPE_TEST}</td>
              <td className="links"><a target="_blank" rel="noopener noreferrer" href={`/api/pdf/${index}`}>PDF</a>
              </td>
              <td className="links"><a href={'#'} onClick={() => this.sendEmail(index)}>Send Email</a></td>
              <td className="status">{entry.status || ''}</td>
            </tr>
          )}
        </table>
      </div>
    )
  }

  async amIAdmin() {
    const { result } = await (await fetch('/api/amIAdmin')).json()
    return result
  }

  logged() {
    return (
      <div>
        <BrowserRouter>
          <header>
            <div style={{
              display: 'flex',
              justifyContent: 'end',
              alignItems: 'center',
              gap: '10px',
              marginBottom: '10px'
            }}>
              <Link to="/">Home</Link>
              {this.state.admin ? <Link to="/users">Users</Link> : <></>}
              <div style={{ flexGrow: 4 }}/>
              <img style={{ width: '1.5em' }} src={this.state.profile.picture}/>
              <div>{this.state.profile.name}</div>
              <SignOut/>
            </div>
          </header>
          <Switch>
            <Route exact path="/">
              {this.state.profile.authorized ? this.main() : <div
                style={{ textAlign: 'center', padding: '100px' }}>You're not authorized to use
                this application. Please contact <a href="mailto:david.paxton@pysertesting.com">David Paxton</a>.
              </div>}
            </Route>
            {!this.state.admin ? <></> : <Route path="/users">
              <Users meta={meta}/>
            </Route>}

          </Switch>
        </BrowserRouter>
      </div>
    )
  }

  render() {
    return (
      <div>
        {this.state.profile ? this.logged() : <div>{this.state.status}</div>}
      </div>
    )
  }
}

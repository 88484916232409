import React from 'react'
import { postJson } from './util'

export class Users extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      users: '',
      admins: '',
      unknown: ''
    }
  }

  async componentDidMount() {
    const res = await fetch('/api/users')
    this.setState(await res.json())
  }

  async save() {
    await postJson('/api/users', this.state)
  }

  async setUnknown(event) {
    this.setState({ unknown: event.target.value })
  }

  async setAdmins(event) {
    this.setState({ admins: event.target.value })
  }

  async setUsers(event) {
    this.setState({ users: event.target.value })
  }

  render() {
    return (
      <div style={{ display: 'flex', flexFlow: 'column', alignContent: 'flex-end', gap: '10px' }}>
        <div style={{ display: 'flex', justifyContent: 'end', alignItems: 'center', gap: '10px', height: '300px' }}>
          <div style={{ display: 'flex', flexGrow: 1, flexFlow: 'column', alignSelf: 'stretch' }}>
            <span>Unknown</span>
            <textarea style={{ flexGrow: 1, alignSelf: 'stretch' }} value={this.state.unknown}
                      onChange={this.setUnknown.bind(this)}/>
          </div>

          <div style={{ display: 'flex', flexGrow: 1, flexFlow: 'column', alignSelf: 'stretch' }}>
            <span>Users</span>
            <textarea style={{ flexGrow: 1, alignSelf: 'stretch' }} value={this.state.users}
                      onChange={this.setUsers.bind(this)}/>
          </div>

          <div style={{ display: 'flex', flexGrow: 1, flexFlow: 'column', alignSelf: 'stretch' }}>
            <span>Admins</span>
            <textarea style={{ flexGrow: 1, alignSelf: 'stretch' }} value={this.state.admins}
                      onChange={this.setAdmins.bind(this)}/>
          </div>
        </div>
        <div style={{ display: 'flex', alignSelf: 'flex-end' }}>
          <button onClick={() => this.save()}>Save</button>
        </div>
      </div>
    )
  }
}

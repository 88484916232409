import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/database'

export const app = firebase.initializeApp({
  apiKey: 'AIzaSyCBE-5SzGS1zAc2DX8s1B-GriCvgRK-wgY',
  authDomain: 'zaphod-33439.firebaseapp.com',
  projectId: 'zaphod-33439',
  storageBucket: 'zaphod-33439.appspot.com',
  messagingSenderId: '891686124131',
  appId: '1:891686124131:web:ba2db8cf597a5f5eb0d656',
  measurementId: 'G-DR5DFH4RDM',
  databaseURL: 'https://zaphod-33439-default-rtdb.europe-west1.firebasedatabase.app'
})

export const db = app.database()

// firebase.database.enableLogging(true);

export const auth = firebase.auth()
